.page-header {
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #fff;
  color: $dark-blue;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
  @media screen and (max-width: $tab_Device){
    height: 48px;
    font-size: 18px;
  }
  .pageBack {
    width: 60px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    height: 100%;
    i {
      font-size: 24px;
      @media screen and (max-width: $tab_Device){
        font-size: 20px;
      }
    }
  }
}
.tournament-content {
  padding: 0 20px 0px;
  @media screen and (max-width: $tab_Device){
    padding: 0 10px 0px;
}
}
.tournament-landingPage {
  /* background-color: $secondaryColor */
  border-top: 1px solid #fff;
  .btn-group {
    padding: 10px 0;
  }
  .tournament-content {
    .tabSection {
      background-color: #fff;
      padding: 0px 20px 20px;
      width: unset;
      // border-radius: 4px;
      @media screen and (max-width: $tab_Device){
        padding: 0px 10px 20px;
      }
    }
    .tabsContent {
      border: 1px solid #ccc;
      border-radius: 3px;
      /* padding: 10px; */
      background-color: #dde4ed;
    }
    .tabSection .tabsContent {
      padding-top: 0px;
    }
  }
}
.countryTabList {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-top: 20px;
  li {
    /* display: inline-block; */
    display: flex;
    font-size: 17px;
    font-weight: 600;
    width: 326px;
    @media screen and (max-width: $tab_Device){
      width: 250px;
      font-size: 14px;
    }
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #222222;
      padding: 2px 15px;
      cursor: pointer;
    }
    &.active a {
      border: 1px solid #ccc;
      background-color: #dde4ed;
      border-bottom-color: #dde4ed;
      border-radius: 3px 3px;
      position: relative;
      bottom: -2px;
      width: 326px;
      border-bottom: solid 1px #ededed;
      min-height: 40px;
      padding-left: 15px;
      @media screen and (max-width: $tab_Device){
        width: 217px;
      }
    }
    .country-flag {
      margin: 4px 10px 4px 0px;
      border: solid 1px #a5a5a5;
    border-radius: 50%;
    }
  }
}
.countryTabContent ul li {
  width: 295px;
  height: 28px;
  padding: 7px 15px;
  /* margin: 3px 0; */
  display: inline-flex;
  align-items: center;
  color: #222222;
  font-size: 15px;
  font-weight: 600;
  border-right: solid 1px #ededed;
  border-bottom: solid 1px #ededed;
  cursor: pointer;
  @media screen and (max-width: $tab_Device){
    width: 217px;
    font-size: 13px;
  }
}
.monthCarousel {
  text-align: center;
  margin: 0 auto;
  width: 750px;
  @media screen and (max-width: $tab_Device){
    width: 435px;
  }
  ul {
    display: inline-flex;
    li {
      display: inline-flex;
      flex-direction: column;
      border: 1px solid #ddd;
      align-items: center;
      padding: 6px 6px;
      width: 90px;
      line-height: 20px;
      cursor: pointer;
    }
  }
}
.month-list {
  width: 100%;
  height: 100%;
  > div {
    width: 100%;
    height: 100%;
    line-height: 25px;
    @media screen and (max-width: $tab_Device){
      line-height: 19px;
      font-size: 13px;
    }
  }
}
.monthCarousel ul li.active {
  background-color: #ddd;
  color: #001a69;
  font-weight: 600;
}
.slick-initialized .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 123px !important;
  border: solid 1px #fff;
  height: 50px;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  @media screen and (max-width: $tab_Device){
    height: 40px;
    width: 70px !important;
  }
}
.slick-slide {
  > div {
    width: 100%;
    height: 100%;
  }
}
.slick-list {
  :focus {
    outline: none;
  }
  .active {
    background-color: #ddd;
    color: #001a69;
    font-weight: 600;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 123px;
    outline: none;
    @media screen and (max-width: $tab_Device){
      height: 40px;
      width: 70px;
    }
  }
}
.slider {
  width: 100%;
  height: 100%;
}
.selectedMonthHeading {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  border-bottom: 1px solid #fff;
  padding: 12px;
  @media screen and (max-width: $tab_Device){
    font-size: 20px;
}
}
.fixture-info {
  display: flex;
  align-items: center;
  justify-content: center;
  .team-info {
    flex: 1 1 0%;
    min-width: 0;
  }
  .kickoff {
    background-color: transparent;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: normal;
    border-radius: 3px;
    min-width: 90px;
    height: 28px;
    margin: 0 10px;
    span{
      margin-left: 5px;
      font-size: 15px;
      align-items: center;
    }
    @media screen and (max-width: $tab_Device){
      font-size: 13px;
      min-width: 70px;
      height: 22px;
      span{
        font-size: 12px;
        padding-top: 2px;
      }
  }
    &.score {
      background-color: #001a69;
      color: #fff;
      font-weight: 600;
    }
  }
}
.league-rules {
  color: #333;
  line-height: 2.5em;
  .sectionHeading {
    margin: 0 -20px 20px;
  }
}
.league-rules-content {
  background-color: #fff;
  padding: 0px 20px 10px;
}
.rules-label {
  color: $dark-blue;
  font-weight: 600;
  display: inline-block;
  line-height: 35px;
}
.rulesContent{
  line-height: 20px;
  span p{
    margin: 5px 0;
  }
}
.sectionSubHeading {
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #425a7b;
  color: #fff;
  padding: 0 10px;
  font-size: 15px;
  margin: 10px 0;
}
.player-pic {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #ddd;
  margin: 3px 10px;
}
.timestamp-data{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #052754;
  .timeStamp {
    // margin-top: -20px;
    padding: 10px 20px;
    text-align: right;
    color: #fff;
  }
  .correction{
    font-size: 13px;
    padding-left: 20px;
    line-height: 18px;
  }
}


.headToHeadSearch {
  border-top: 1px solid #fff;
  .contentBg {
    padding: 30px 100px;
    text-align: center;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(#{$img-path}/bg.jpg);
  }
}
.headToHeadSearch-heading {
  text-transform: uppercase;
  color: #ff6f29;
  font-family: HelveticaCondensed;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 20px;
}
.headToHead-teamSearch {
  .headToHead-teamSearchGroup {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 40px;
  }
  .form-label {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }
  .form-control input {
    width: 350px;
    height: 38px;
    font-size: 14px;
  }
  .team-vs {
    height: 40px;
    line-height: 40px;
    margin: 0 20px;
    color: #ff6f29;
    font-size: 18px;
    font-weight: 600;
  }
  .btn {
    width: 270px;
    height: 50px;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }
}
.headToHead-teamFilter {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  .form-group {
    margin: 0 10px;
  }
  .form-label {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }
  .form-control select {
    width: 370px;
    height: 40px;
    font-size: 15px;
  }
}
.orDivider {
  margin: 40px 0;
  font-size: 20px;
  font-weight: bold;
  color: #ff6f29;
  overflow: hidden;
  text-align: center;
  &::after {
    background-color: #fff;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 35%;
  }
  &::before {
    background-color: #fff;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 35%;
    left: 2.5em;
    margin-right: -50%;
  }
  &::after {
    right: 2.5em;
    margin-left: -50%;
  }
}
.headToHead-teamSearchSelection {
  margin-top: 50px;
  ul {
    margin-left: -20px;
    margin-right: -20px;
    li {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 210px;
      height: 40px;
      border-radius: 10px 0 10px 0;
      padding: 5px;
      background-color: #fff;
      font-size: 16px;
      margin: 0 5px 10px;
      position: relative;
      cursor: pointer;
      border: 1px solid #fff;
      &:hover {
        background-color: $dark-blue;
        font-weight: 600;
        color: #fff;
      }
    }
  }
  .active {
    background-color: $tertiaryColor !important;
  }
  ul li {
    &:hover::after {
      position: absolute;
      top: 0;
      right: 0;
      content: "+";
      width: 20px;
      height: 20px;
      background-color: #fff;
      color: $dark-blue;
    }
    img {
      height: 30px;
      padding: 0 6px;
    }
  }
}
.groupTable-heading{
  width: 100%;
  margin-top: 20px;
}